import * as d3 from 'd3';

if (typeof docraptorJavaScriptFinished === 'undefined') {
    window.docraptorJavaScriptFinished = function () {
        return false;
    };
}

document.addEventListener("DOMContentLoaded", function (_event) {
    if (!document.querySelector(".price-history-chart") || !gon.price_history_for_post_url) {
        return;
    }

    var ratio = 3;
    var containerWidth = 1424;
    var containerHeight = Math.max(360, Math.floor(containerWidth / ratio));

    // set the dimensions and margins of the graph
    var margin = { top: 30, right: 20, bottom: 50, left: 70 },
        width = containerWidth - margin.left - margin.right,
        height = containerHeight - margin.top - margin.bottom;

    // set the ranges
    var x = d3.scaleTime().range([0, width]);
    var y = d3.scaleLinear().range([height, 0]);

    // define the line
    var valueline = d3.line()
        .x(function (d) { return x(d.date); })
        .y(function (d) { return y(d.price); });

    // Remove placeholder header
    d3.select(".price-history-chart").style("break-inside", "avoid").html(null);

    // Append the chart title and SVG container
    var chartTitle = d3.select(".price-history-chart").append("div")
        .attr('class', 'chart-title')
        .style("text-align", "center")
        .style("margin-bottom", "1rem")
        .style("font-size", "14px")
        .style("font-weight", "bold");

    var chartNotes = d3.select(".price-history-chart").append("div")
        .attr('class', 'chart-notes')
        .style("margin-left", margin.left + "px");

    var svg = d3.select(".price-history-chart").append("svg")
        .attr("viewBox", "0 0 " + containerWidth + " " + containerHeight)
        .attr("width", "100%")
        .attr("height", "100%");

    var chart = svg.append("g")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    // Get the data using D3 version 4 callback pattern
    d3.json(gon.price_history_for_post_url, function (error, response) {
        if (error) throw error;

        chartTitle.text(response.title);

        var data = response.data;
        data.forEach(function (d) {
            d.date = new Date(d.date);
            d.price = +d.price;
        });

        // Scale the range of the data
        var min_val = d3.min(data, function (d) { return d.price; });
        var max_val = d3.max(data, function (d) { return d.price; });

        x.domain(d3.extent(data, function (d) { return d.date; }));
        y.domain([min_val - (max_val * 0.1), max_val * 1.1]);

        // Add the valueline path
        chart.append("path")
            .data([data])
            .attr("class", "line")
            .attr("stroke", "steelblue")
            .attr("fill", "none")
            .attr("stroke-width", 2)
            .attr("d", valueline);

        // Add the x Axis
        chart.append("g")
            .attr("transform", "translate(0," + height + ")")
            .call(d3.axisBottom(x).tickFormat(d3.timeFormat("%b'%y")));

        // Add the y Axis
        chart.append("g")
            .call(d3.axisLeft(y));

        // Add grid lines
        chart.append("g")
            .attr("class", "grid")
            .call(
                d3.axisLeft(y)
                    .tickSize(-width, 0, 0)
                    .tickFormat("")
            );

        // Add axis label
        chart.append("text")
            .attr("transform", "rotate(-90)")
            .attr("y", 0 - margin.left)
            .attr("x", 0 - (height / 2))
            .attr("dy", "1em")
            .style("text-anchor", "middle")
            .style("font-size", "12px")
            .style("fill", "currentColor")
            .style("font-weight", "bold")
            .text("Closing Price $");

        // If notes exist, display them
        if (response.notes.length > 0) {
            response.notes.forEach(function (n, i) {
                var noteDateFormatted = formatDate(n.date);
                var correspondingDataPoint = data.find(function (d) {
                    return formatDate(d.date) === noteDateFormatted;
                });

                if (correspondingDataPoint) {
                    var noteDiv = chartNotes.append("div")
                        .style("display", "inline-block");

                    var noteP = noteDiv.append("p")
                        .attr("class", "note-box-" + i + " note-p")
                        .style("font-size", "10px")
                        .style("margin", "0 2px")
                        .style("padding", "0px 10px")
                        .style("border", "solid 1px")
                        .html(n.rating + ": " + n.price_target + "<br>" + n.date);

                    console.log("Note created for note:", i, noteP);

                    var noteElement = document.querySelector(".note-box-" + i);
                    var priceChart = document.querySelector(".price-history-chart");

                    var priceChartPosition = priceChart.getBoundingClientRect();
                    var notePosition = noteElement.getBoundingClientRect();

                    var startX = (notePosition.left - priceChartPosition.left) + notePosition.width / 2;
                    var startY = 0;

                    var graphPointX = x(correspondingDataPoint.date) + margin.left;
                    var graphPointY = y(correspondingDataPoint.price) + margin.top;

                    // Append a line from the note to the graph point
                    svg.append("line")
                        .style("stroke", "black")
                        .style("stroke-width", 1)
                        .attr("x1", startX)
                        .attr("y1", startY)
                        .attr("x2", graphPointX)
                        .attr("y2", graphPointY);
                }
            });
        }

        // Utility function to format date
        function formatDate(input) {
            var date = new Date(input);
            var year = date.getUTCFullYear();
            var month = date.getUTCMonth() + 1;
            var day = date.getUTCDate();

            month = month < 10 ? '0' + month : month;
            day = day < 10 ? '0' + day : day;

            return year + "-" + month + "-" + day;
        }

        var didWait = false;

        docraptorJavaScriptFinished = function() {
            if (!didWait) {
                setTimeout(function(){
                    didWait = true;
                }, 10000);
                return false;
            }

            return true;
        };
    });
});
